<template>
  <b-card>
    <template #header>
      <h6 class="mb-0">Account Details</h6>
    </template>
    <b-card-text>
      <div class="content">
        <FormTemplate v-if="form" :form="form">
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="label-for-xl" for="inputFirstName">First Name</label>
                        <b-form-input
                            type="text" id="inputFirstName" class="form-control form-control-xl"
                            placeholder="enter first name"
                            v-model="form.data.firstName"
                            :state="form.states.firstName"
                        />
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group">
                        <label class="label-for-xl" for="inputLastName">Last Name</label>
                        <b-form-input
                            type="text" id="inputLastName" class="form-control form-control-xl"
                            placeholder="enter last name"
                            v-model="form.data.lastName"
                            :state="form.states.lastName"
                        />
                    </div>
                </div>
            </div>
        </FormTemplate>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';
export default {
  props: ['member'],
  components: {
      FormTemplate
  },
  data(){
      return {
          form: null
      };
  },
  mounted(){
    this.$emit('set-member-sidebar',{active: "member.details"});
    this.form = new Form({
        firstName: this.member.get('firstName') || "",
        lastName: this.member.get('lastName') || "",
    },{
        onSubmit: (form) => {
            return this.$api.put(`user/${this.member.get('id')}/account`, {
                data: {firstName: form.get('firstName'),lastName: form.get('lastName')}
            })
        },
        onSuccess: (form, {data}) => {
          this.$emit('update-member', {member: data});
          this.$store.dispatch('Notification/addNotification', {event: "onTeamUpdate", member: this.member});
        },
        onError: function(form, err){
            console.log(err);
        }
    });
  },
};
</script>
